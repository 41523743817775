var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('Loader',{attrs:{"isLoading":_vm.isLoader}}),_c('router-link',{class:_vm.$style.create,attrs:{"to":"client-requesites/create"}},[_c('Icon',{class:_vm.$style.icon,attrs:{"name":"plus"}}),_vm._v(" Создать новые реквизиты ")],1),_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.list,"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'isVerified')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.formattedRow[props.column.field],
            },
          ]})]):(props.column.field == 'isIndividualEntrepreneur')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.formattedRow[props.column.field],
            },
          ]})]):(props.column.field == 'action' && _vm.isAdminOrOwner)?_c('span',{class:_vm.$style.actionsBtn},[_c('router-link',{attrs:{"to":`/sellers/client-requesites/${props.row.id}`}},[_c('Icon',{class:_vm.$style.icon,attrs:{"name":"edit"}})],1),_c('div',{class:_vm.$style.delete},[_c('Icon',{class:_vm.$style.icon,attrs:{"name":"trash"},on:{"click":function($event){return _vm.removeClientRequesites(props.row.id)}}})],1)],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }