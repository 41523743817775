<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <router-link to="client-requesites/create" :class="$style.create">
      <Icon name="plus" :class="$style.icon" /> Создать новые реквизиты
    </router-link>
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="list"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'isVerified'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.formattedRow[props.column.field],
              },
            ]"
          />
        </span>
        <span v-else-if="props.column.field == 'isIndividualEntrepreneur'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.formattedRow[props.column.field],
              },
            ]"
          />
        </span>
        <span
          v-else-if="props.column.field == 'action' && isAdminOrOwner"
          :class="$style.actionsBtn"
        >
          <router-link :to="`/sellers/client-requesites/${props.row.id}`">
            <Icon name="edit" :class="$style.icon" />
          </router-link>
          <div :class="$style.delete">
            <Icon
              name="trash"
              :class="$style.icon"
              @click="removeClientRequesites(props.row.id)"
            />
          </div>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Loader from '@/components/atoms/LoadingBar.vue'
import Icon from '@/components/atoms/Icon'
import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Loader,
  },
  data() {
    return {
      columns: [
        {
          label: 'Статус',
          field: 'isVerified',
          tooltip: 'Статус модерации пользователя',
        },
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'УНП',
          field: 'unp',
        },
        {
          label: 'Телефон',
          field: 'phone',
        },
        {
          label: 'ИП',
          field: 'isIndividualEntrepreneur',
        },
        {
          label: 'Почта',
          field: 'email',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      list: null,
      isLoader: false,
    }
  },
  computed: {
    ...mapGetters({
      isAdminOrOwner: 'isAdminOrOwner',
    }),
  },
  mounted() {
    this.getDealersClientRequesites()
  },
  methods: {
    async getDealersClientRequesites() {
      this.isLoader = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getClientRequesites(
          this.$route.params.id,
        )
      if (error) return
      this.list = value
      this.isLoader = false
    },
    async removeClientRequesites(requesitesId) {
      const isConfirm = confirm(
        `Вы уверены, что хотите удалить реквизиты ${
          this.list.find((x) => x.id === requesitesId).name
        }`,
      )
      if (isConfirm) {
        this.isLoader = true
        const res =
          await delivery.AddwineCore.DealersActions.deleteClientRequesites(
            this.$route.params.id,
            requesitesId,
          )
        if (!res.error) {
          this.getDealersClientRequesites()
        }
        this.isLoader = false
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 0.0625rem solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;
    color: $dark-gray;
    &:hover {
      background: $white;
    }
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $error;
  }
  .successStatus {
    background-color: $complite;
  }
  .actionsBtn {
    width: 1rem;
    display: flex;
    gap: 1rem;
  }
  .icon {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}
</style>
